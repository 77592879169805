// Base Styles

/* Paragraphs */
p {
  margin-bottom: $paragraph-margin-bottom;
}

/* Forms */
label {
  font-size: 14px;
  font-weight: $font-weight-bold;
}

.table-fix-head {
  overflow: auto;
  height: calc(100vh - 300px);

  thead {
    th {
      background: $white;
      position: sticky;
      top: 0;
      z-index: 1;
    }
  }
}

.admin-view {
  font-size: 0.85rem;
}