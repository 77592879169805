// AppHeader.js styles
#AppHeader {
  padding: 10px 0;
  border-bottom: 1px solid $primary;

  .last-name {
    text-transform: uppercase;
  }

  #keolis-logo {
    width: 150px;
    height: 46px;
  }
}
